import React, { createContext, useState, useEffect, useCallback } from "react";

// Create the context
export const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showAdminPanel, setShowAdminPanel] = useState(false);

  const handleLogout = async () => {
    try {
      const response = await fetch(
        "https://wikiguessr-6d5a3c1aebf6.herokuapp.com/logout",
        {
          method: "POST",
          credentials: "include", // Include cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        // Clear any local state related to the user
        setUsername("");
        setShowAdminPanel(false);
        setLoggedIn(false);
        setIsAdmin(false);
        alert("You have been logged out successfully.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const toggleAdminPanel = useCallback(async () => {
    //await checkUserAuthentication();
    if (isAdmin) {
      setShowAdminPanel(!showAdminPanel);
    } else {
      alert("Access denied. Admins only.");
    }
  }, [isAdmin, showAdminPanel]);

  // Function to check authentication status
  const checkUserAuthentication = async () => {
    try {
      const response = await fetch(
        "https://wikiguessr-6d5a3c1aebf6.herokuapp.com/verify-token",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setLoggedIn(true);
        setUsername(data.username);
        setIsAdmin(data.admin);
      } else {
        setLoggedIn(false);
        setUsername("");
        setIsAdmin(false);
      }
    } catch (error) {
      setLoggedIn(false);
      setUsername("");
      setIsAdmin(false);
    }
  };

  // Run the authentication check when the component mounts
  useEffect(() => {
    checkUserAuthentication();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        username,
        isAdmin,
        handleLogout,
        showAdminPanel,
        setShowAdminPanel,
        toggleAdminPanel,
        setLoggedIn,
        setIsAdmin,
        setUsername,
        checkUserAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
