import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./homePage.css";

const HomePage = () => (
  <div className="rules-section">
    <h2>Game Rules</h2>
    <p>
      The goal of the game is to navigate from the Start article to the End
      article using only Wikipedia links. Click on links within the articles to
      move from one article to another. Try to reach the End article as quickly
      as possible!
    </p>
    <Link to="/game">
      <button className="start-game-btn">Start Game</button>{" "}
    </Link>
  </div>
);

export default HomePage;
