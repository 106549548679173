import React from "react";
import "./lobby.css";

const Lobby = ({
  lobbyPlayers,
  openLobbies,
  lobbyId,
  isHost,
  loading,
  categories,
  selectedCategory,
  handleCreateLobby,
  handleJoinLobby,
  handleLeaveLobby,
  handleStartGameClick,
  handleCategorySelect,
}) => {
  return (
    <div className="lobby-info">
      {lobbyId ? (
        <>
          <h3>Players in Lobby:</h3>
          <ul>
            {lobbyPlayers.map((player, index) => (
              <li key={index}>
                {player.username} {player.isHost && "(HOST)"}
              </li>
            ))}
          </ul>

          <button onClick={handleLeaveLobby} className="difficulty-btn">
            Leave Lobby
          </button>
        </>
      ) : (
        <>
          {openLobbies.length > 0 && (
            <div className="open-lobbies">
              <h3>Open Lobbies:</h3>
              <ul>
                {openLobbies.map((lobby) => (
                  <li key={lobby.lobbyId}>
                    Host: {lobby.hostUsername} - Players: {lobby.playerCount}{" "}
                    {lobby.gameStarted && "(GAME IN PROGRESS)"}
                    {!lobby.gameStarted && (
                      <>
                        <span> | Category: {lobby.selectedCategory}</span>{" "}
                        {/* Display the selected category */}
                        <button
                          onClick={() => handleJoinLobby(lobby.lobbyId)}
                          className="difficulty-btn"
                        >
                          Join
                        </button>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="lobby-controls">
            <button onClick={handleCreateLobby} className="difficulty-btn">
              Create Lobby
            </button>
          </div>
        </>
      )}

      {isHost && (
        <div className="start-game-section">
          <button onClick={handleStartGameClick} className="difficulty-btn">
            {loading ? "Loading..." : "Start Game"}
          </button>
          <select
            id="category"
            className="select-category"
            value={selectedCategory}
            onChange={(e) => handleCategorySelect(e.target.value)}
          >
            <option value="">Any Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default Lobby;
