import React, { useEffect, useState, useContext } from "react";
import io from "socket.io-client";
import FetchArticle from "../FetchArticle";
import Lobby from "../components/Lobby";
import ArticleContent from "../components/ArticleContent";
import GameControls from "../components/GameControls";
import Scoreboard from "../components/Scoreboard";
import { AuthContext } from "../context/AuthContext";

// Update the Socket.IO client connection URL
const socket = io("https://wikiguessr-6d5a3c1aebf6.herokuapp.com"); // Adjust the URL as necessary

const Game = ({ loading }) => {
  const [articleContent, setArticleContent] = useState(null); // Keep articleContent client-side
  const [startArticle, setStartArticle] = useState(null);
  const [endArticle, setEndArticle] = useState(null);
  const [startCategory, setStartCategory] = useState([]);
  const [endCategory, setEndCategory] = useState([]);
  const [history, setHistory] = useState([]); // Keep history client-side
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [mainMenu, setmainMenu] = useState(true);
  const [scoreboard, setScoreboard] = useState([]); // To store the scoreboard data
  const [winner, setWinner] = useState("");
  const [categories, setCategories] = useState([]); // Store categories
  const [selectedCategory, setSelectedCategory] = useState(""); // Store selected category
  const [showLoginForm, setShowLoginForm] = useState(false);

  const [showRegisterForm, setShowRegisterForm] = useState(false);

  // New state variables for lobby functionality
  const [lobbyId, setLobbyId] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [openLobbies, setOpenLobbies] = useState([]);
  const [lobbyPlayers, setLobbyPlayers] = useState([]);

  const { username, loggedIn } = useContext(AuthContext);

  document.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.key === "f") {
      // Prevent default Ctrl + F behavior
      event.preventDefault();

      handleGiveUp();
    }
  });

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);

    // Emit the selected category to the server (if required)
    socket.emit("updateCategory", { lobbyId, category });

    console.log("Selected category:", category);
  };

  useEffect(() => {
    // Fetch categories from the backend
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://wikiguessr-6d5a3c1aebf6.herokuapp.com/categories"
        );
        const data = await response.json();
        const validCategories = data.categories.filter(
          (category) => category && category.trim() !== ""
        );
        setCategories(validCategories); // Store the categories in state
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Timer effect: increments time every second when the timer is running
  useEffect(() => {
    let timerInterval = null;
    if (isRunning) {
      timerInterval = setInterval(() => {
        setTime((prevTime) => {
          let { minutes, seconds } = prevTime;
          seconds += 1;
          if (seconds >= 60) {
            seconds = 0;
            minutes += 1;
          }
          return { minutes, seconds };
        });
      }, 1000);
    } else if (!isRunning && timerInterval) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval); // Clean up interval on unmount or stop
  }, [isRunning]);

  // Scroll to the top of the article when article content changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [articleContent]);

  // Socket.IO event listeners
  useEffect(() => {
    // Request the list of open lobbies when the component mounts
    socket.emit("getOpenLobbies");

    // Listen for updates to the lobby list
    socket.on("openLobbies", (lobbies) => {
      setOpenLobbies(lobbies);
    });

    socket.on("lobbyListUpdated", (lobbies) => {
      setOpenLobbies(lobbies);
    });

    // Listen for game state updates (this should not update the articleContent)
    socket.on("gameStateUpdate", (gameState, selectedCategory) => {
      console.log("Game State:", gameState); // Debugging
      if (gameState) {
        setStartArticle(gameState.startArticle);
        setEndArticle(gameState.endArticle);
        setStartCategory(gameState.startCategory || []); // Set categories, or default to empty array
        setEndCategory(gameState.endCategory || []); // Set categories, or default to empty array
        setTime(gameState.time);
        setGameCompleted(gameState.gameCompleted);
        setIsRunning(gameState.isRunning);
        setmainMenu(gameState.mainMenu);
        setSelectedCategory(gameState.selectedCategory);

        console.log("Start Category:", gameState.startCategory); // Debugging
        console.log("End Category:", gameState.endCategory); // Debugging

        // Fetch the start article for the player
        if (!gameState.gameCompleted) {
          FetchArticle(gameState.startArticle).then((result) => {
            if (!result.error) {
              setArticleContent(result);
            }
          });
        }
      }
    });

    // Listen for game result (when a player wins)
    socket.on("gameResult", ({ winner, players }) => {
      setWinner(winner);
      setScoreboard(players);
      setIsRunning(false); // Stop the timer for all players
      setGameCompleted(true); // Mark the game as completed
    });

    // Listen for lobby players updates
    socket.on("lobbyPlayersUpdate", (players) => {
      setLobbyPlayers(players);
    });

    // Clean up the listeners on unmount
    return () => {
      socket.off("openLobbies");
      socket.off("lobbyListUpdated");
      socket.off("gameStateUpdate");
      socket.off("gameResult");
      socket.off("lobbyPlayersUpdate");
      socket.off("updateCategory");
    };
  }, []);

  // Handle creating a new lobby
  const handleCreateLobby = () => {
    if (!loggedIn) {
      const inputUsername = prompt("Enter your username:");
      if (!inputUsername) return;
      const guestUsername = inputUsername + " (Guest)";
      socket.emit("createLobby", { username: guestUsername }, ({ lobbyId }) => {
        setLobbyId(lobbyId);
        setIsHost(true);
      });
    } else {
      socket.emit("createLobby", { username }, ({ lobbyId }) => {
        setLobbyId(lobbyId);
        setIsHost(true);
      });
    }
  };

  // Handle joining a lobby
  const handleJoinLobby = (lobbyIdToJoin) => {
    if (!loggedIn) {
      const inputUsername = prompt("Enter your username:");
      if (!inputUsername) return;
      const guestUsername = inputUsername + " (Guest)";
      socket.emit(
        "joinLobby",
        { lobbyId: lobbyIdToJoin, username: guestUsername },
        (response) => {
          if (response.success) {
            setLobbyId(lobbyIdToJoin);
            setIsHost(false);
          } else {
            alert(response.message);
          }
        }
      );
    } else {
      socket.emit(
        "joinLobby",
        { lobbyId: lobbyIdToJoin, username },
        (response) => {
          if (response.success) {
            setLobbyId(lobbyIdToJoin);
            setIsHost(false);
          } else {
            alert(response.message);
          }
        }
      );
    }
  };

  // Handle leaving a lobby
  const handleLeaveLobby = () => {
    socket.emit("leaveLobby", { lobbyId });
    setLobbyId("");
    setIsHost(false);
    // Reset game state if needed
    setIsRunning(false);
    setTime({ minutes: 0, seconds: 0 });
    setHistory([]);
    setmainMenu(true);
    setStartArticle(null);
    setEndArticle(null);
    setArticleContent(null);
    setGameCompleted(false);
    setLobbyPlayers([]);
    setShowLoginForm(false);
    setShowRegisterForm(false);
  };

  // Handle game reset and leave the lobby
  const handleGiveUp = () => {
    socket.emit("leaveLobby", { lobbyId });
    setIsRunning(false); // Stop the timer
    setTime({ minutes: 0, seconds: 0 }); // Reset the timer
    setHistory([]); // Clear history
    setmainMenu(true); // Show the rules again
    setStartArticle(null); // Reset articles
    setEndArticle(null);
    setArticleContent(null); // Clear the article content
    setGameCompleted(false); // Reset game completion state
    handleLeaveLobby(); // Leave the lobby
  };

  // Handle starting the game
  const handleStartGameClick = async () => {
    if (!isHost) return; // Only the host can start the game
    setHistory([]); // Empty the history array
    setTime({ minutes: 0, seconds: 0 }); // Reset the timer
    setIsRunning(true); // Start the timer
    setGameCompleted(false); // Reset game completion state
    setmainMenu(false); // Hide rules when game starts
    socket.emit("startGame", { lobbyId, selectedCategory }); // Emit the startGame event to the server
  };

  // Handle link clicks and check if the end article has been reached
  // Handle link clicks and check if the end article has been reached
  const handleLinkClick = async (event) => {
    const link = event.target.closest("a");
    if (link) {
      event.preventDefault(); // Prevent default link behavior

      const linkUrl = link.href; // Get the full URL of the clicked link
      const linkText = link.textContent.trim(); // Get the text of the clicked link

      // Extract the article title from the URL (assuming Wikipedia link structure)
      let articleTitle = new URL(linkUrl).pathname.split("/").pop();
      const linkClick = link.href.split("/").pop(); // Extracts 'Provinces_and_territories_of_Canada'

      // Replace any underscores with spaces to format the article title correctly
      articleTitle = articleTitle.replace(/_/g, " ");

      const result = await FetchArticle(decodeURIComponent(linkClick)); // Fetch article based on the article title from the URL

      if (!result.error) {
        setArticleContent(result); // Update article content with the link result

        // Update history
        const updatedHistory = [...history, linkText];
        setHistory(updatedHistory);

        // Emit the updated history to the server
        socket.emit("updatePlayerHistory", {
          lobbyId,
          playerName: username,
          history: updatedHistory,
        });

        // Normalize strings for comparison
        const normalizeString = (str) => {
          return str
            .replace(/–|—|−/g, "-") // Replace en dash, em dash, and minus sign with hyphen
            .replace(/%20/g, " ") // Replace encoded spaces with actual spaces if necessary
            .trim() // Remove leading/trailing spaces
            .toLowerCase(); // Convert to lowercase for case-insensitive comparison
        };

        // Check if the user has reached the end article
        if (normalizeString(articleTitle) === normalizeString(endArticle)) {
          socket.emit("playerWin", {
            lobbyId,
            playerName: username,
            history: updatedHistory,
          });
          console.log(
            "Player has reached the end article!",
            articleTitle,
            endArticle
          );
        }
      }
    }
  };

  return (
    <div className="App">
      <div className="content">
        {gameCompleted ? (
          <Scoreboard
            scoreboard={scoreboard}
            winner={winner}
            startArticle={startArticle}
            endArticle={endArticle}
            handleGiveUp={handleGiveUp}
          />
        ) : mainMenu ? (
          <Lobby
            lobbyPlayers={lobbyPlayers}
            openLobbies={openLobbies}
            lobbyId={lobbyId}
            isHost={isHost}
            loading={loading}
            categories={categories}
            selectedCategory={selectedCategory}
            handleCreateLobby={handleCreateLobby}
            handleJoinLobby={handleJoinLobby}
            handleLeaveLobby={handleLeaveLobby}
            handleStartGameClick={handleStartGameClick}
            handleCategorySelect={handleCategorySelect}
          />
        ) : (
          lobbyId &&
          !showLoginForm &&
          !showRegisterForm && (
            <>
              <ArticleContent
                articleContent={articleContent}
                loading={loading}
                gameCompleted={gameCompleted}
                handleLinkClick={handleLinkClick}
              />

              {!mainMenu && (
                <GameControls
                  startArticle={startArticle}
                  startCategory={startCategory}
                  endArticle={endArticle}
                  endCategory={endCategory}
                  time={time}
                  history={history}
                  lobbyPlayers={lobbyPlayers}
                  onGiveUp={handleGiveUp}
                />
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default Game;
